/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// any CSS you require will output into a single css file (app.css in this case)
require('../css/app.css');
require('bootstrap');

const WOW = require('wowjs');
// Need jQuery? Install it with "yarn add jquery", then uncomment to require it.
// const $ = require('jquery');





const $ = require('jquery');


global.$ = global.jQuery = $;

require( 'datatables.net-bs4');
import AOS from 'aos';
import 'aos/dist/aos.css';
import './all';
import './custom';
import './inview';

$(document).ready(function(){

    $('#getByNip').on('click', function(){
        $('.error-nip').children().remove();
        let nipVal = $('#registration_form_nip').val();
        let regexp = new RegExp("^[0-9]{10}$");
        if(regexp.test(nipVal) ){
            // AJAX Request
            let path = $(this).attr("data-path");
            $.ajax({
                type: 'POST',
                url: path,
                data: {nip: nipVal},
                cache: false,
                success: function (response) {
                    console.log(response);
                    $('#registration_form_address').val(response.street);
                    $('#registration_form_city').val(response.city);
                    $('#registration_form_zipCode').val(response.zip);
                    $('#registration_form_companyName').val(response.companyName);
                    if(response.error){
                        $('.error-nip').children().remove();
                        let error = "<p>Niestety nie ma firmy z takim numerem NIP</p>";
                        $('.error-nip').append(error);
                    }

                },
                error: function(jqXHR, exception) {
                    $('.error-nip').children().remove();
                    let error = "<p>Niestety nie ma firmy z takim numerem NIP</p>";
                    $('.error-nip').append(error);
                }
            });
        }else{
            $('.error-nip').children().remove();
            let error = "<p>Proszę wpisać pełny numer NIP w odpowiednim formacie.</p>";
            $('.error-nip').append(error);
        }
    });


    window.wow = new WOW.WOW(
        {
            boxClass:     'wow',      // animated element css class (default is wow)
            animateClass: 'animated', // animation css class (default is animated)
            offset:       0,          // distance to the element when triggering the animation (default is 0)
            mobile:       false,       // trigger animations on mobile devices (default is true)
            live:         true,       // act on asynchronously loaded content (default is true)
            callback:     function(box) {
                // the callback is fired every time an animation is started
                // the argument that is passed in is the DOM node being animated
            },
            scrollContainer: null // optional scroll container selector, otherwise use window
        }
    );
    window.wow.init();


    var table = $('.offer-table').DataTable( {
        "bLengthChange": true,
        "ordering": true,
        "paging": true,
        "searching": true,
        "info":     false,
        "language": {
            "processing":     "Przetwarzanie...",
            "search":         "Szukaj:",
            "lengthMenu":     "Pokaż _MENU_ pozycji",
            "info":           "Pozycje od _START_ do _END_ z _TOTAL_ łącznie",
            "infoEmpty":      "Pozycji 0 z 0 dostępnych",
            "infoFiltered":   "(filtrowanie spośród _MAX_ dostępnych pozycji)",
            "infoPostFix":    "",
            "loadingRecords": "Wczytywanie...",
            "zeroRecords":    "Nie znaleziono pasujących pozycji",
            "emptyTable":     "Brak danych",
            "paginate": {
                "first":      "Pierwsza",
                "previous":   "Poprzednia",
                "next":       "Następna",
                "last":       "Ostatnia"
            },
            "aria": {
                "sortAscending": ": aktywuj, by posortować kolumnę rosnąco",
                "sortDescending": ": aktywuj, by posortować kolumnę malejąco"
            }
        }
    } );



    $('#search-offer').on('keyup',function(){
        table.search($(this).val()).draw();
    });


    setTimeout(function() {
        $('#flashMessage').fadeOut('fast');
    }, 5000); // <-- time in milliseconds

    $('#registration_form_telephone').change(function(){
       numberWithSpaces($(this).val());

    });

    function numberWithSpaces(x) {
         let replace = x.replace(/\B(?=(\d{3})+(?!\d))/g, " ");
         $('#registration_form_telephone').val(replace);
    }


    AOS.init({
        duration: 800, // values from 0 to 3000, with step 50ms
        easing: 'ease', // default easing for AOS animations
        once: true,
        startEvent: 'DOMContentLoaded', // name of the event dispatched on the document, that AOS should initialize on

    });

    $(document).on('click','.clickable-row',function(){
        window.location = $(this).data("href");
    });

    // $(".clickable-row").click(function() {
    //     window.location = $(this).data("href");
    // });


    $('#registration_form_pesel').prop('required',false);

    if($('#registration_form_accountType').val()== "Firma"){
        $('.company').removeAttr("style");
        $('.person').css('display', 'none');
        $('#registration_form_pesel').prop('required',false);

        $('#registration_form_nip').prop('required',true);
        $('#registration_form_companyNam').prop('required',true);
    }
    if($('#registration_form_accountType').val() == "Osoba fizyczna"){
        $('.company').css('display', 'none');
        $('.person').css('display' ,'block');
        $('#registration_form_nip').prop('required',false);
        $('#registration_form_companyName').prop('required',false);


        $('#registration_form_pesel').prop('required',true);

    }


    $('#registration_form_accountType').change(function(){
        if($(this).val()== "Firma"){
            $('.company').removeAttr("style");
            $('.person').css('display', 'none');
            $('#registration_form_pesel').prop('required',false);

            $('#registration_form_nip').prop('required',true);
            $('#registration_form_companyNam').prop('required',true);
        }
        if($(this).val() == "Osoba fizyczna"){
            $('.company').css('display', 'none');
            $('.person').css('display' ,'block');
            $('#registration_form_nip').prop('required',false);
            $('#registration_form_companyName').prop('required',false);


            $('#registration_form_pesel').prop('required',true);

        }
    });


    $.expr[':'].containsIgnoreCase = function (n, i, m) {
        return jQuery(n).text().toUpperCase().indexOf(m[3].toUpperCase()) >= 0;
    };

    // $('#search-offer').keyup(function(e) {
    //     clearTimeout($.data(this, 'timer'));
    //     if(e.keyCode == 13)
    //         search(true);
    //     else
    //         $(this).data('timer', setTimeout(search, 100));
    // });
    //
    //
    //
    // function search(force) {
    //     if(this.$('#search-offer')[0].value === '') {
    //         $('#offer-table tbody tr').show();
    //         return;
    //     }
    //     var word = $('#search-offer').val();
    //     var word_filter = $('#offer-table tbody tr ')
    //
    //     if($.trim(word) != '')
    //         word_filter = word_filter.filter(':containsIgnoreCase('+word+')');
    //     $('#offer-table tbody tr  ').hide();
    //     word_filter.show();
    // }


    $('.service-box').mouseover(function(){

       let container = $(this).children('.service-box-wrapper');
       let heightContainer = $(this).height();

       container.css('display','block');
       // container.css('margin-top',heightContainer - 150);
    });

    $('.service-box').mouseleave(function(){
        let container = $(this).children('.service-box-wrapper');
        container.css('display','none');
    });



});

$(function () {
    $('[data-toggle="tooltip"]').tooltip()


    if ($(window).innerWidth() <= 700)
    {

        $('.counter-section').one('inview', function(event, isInView) {
            if (isInView) {
                animateValue("counter1Mobile", 0, 5450500, 3000);
                animateValue("counter2Mobile", 0, 3290, 3000);
                animateValue("counter3Mobile", 0, 7432, 3000);
                animateValue("counter4Mobile", 0, 3432409, 3000);
            } else {
                // element has gone out of viewport
            }
        });
    }

    $('.counter-section').one('inview', function(event, isInView) {
        if (isInView) {
            animateValue("counter1", 0, 5450500, 3000);
            animateValue("counter2", 0, 3290, 3000);
            animateValue("counter3", 0, 7432, 3000);
            animateValue("counter4", 0, 3432409, 3000);
        } else {
            // element has gone out of viewport
        }
    });

    $('#services').one('inview', function(event, isInView) {
        if (isInView) {
            animateValue("counterService", 0, 11234543, 3000);

        } else {
            // element has gone out of viewport
        }
    });

    function animateValue(id, start, end, duration) {
        // assumes integer values for start and end

        var obj = document.getElementById(id);
        var range = end - start;
        // no timer shorter than 50ms (not really visible any way)
        var minTimer = 50;
        // calc step time to show all interediate values
        var stepTime = Math.abs(Math.floor(duration / range));

        // never go below minTimer
        stepTime = Math.max(stepTime, minTimer);

        // get current time and calculate desired end time
        var startTime = new Date().getTime();
        var endTime = startTime + duration;
        var timer;

        function run() {
            var now = new Date().getTime();
            var remaining = Math.max((endTime - now) / duration, 0);
            var value = Math.round(end - (remaining * range));
            obj.innerHTML = value.toLocaleString();
            if (value == end) {
                clearInterval(timer);
            }
        }

        timer = setInterval(run, stepTime);
        run();
    }

});


